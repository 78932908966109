@import 'styles/variables.less';

.br-collapse-container {
  .ant-collapse > .ant-collapse-item:last-child {
    padding-block: 10px;
    border-radius: 12px;
  }
  .br-collapse-icon-down {
    transform: rotate(180deg);
  }
  padding: 96px 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;

  h1 {
    .font({.display-xl()});
    color: @teal-500;
    margin-bottom: 32px;
  }

  .br-collapse-panel {
    width: 77%;
  }
  .ant-collapse {
    margin-bottom: 20px;
    background-color: white;
    border-radius: 12px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
.ant-collapse-content {
  border-top: none !important;
}
.ant-collapse
  > .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-header-text {
  .font({.signup-md()});
  max-width: 896px;
}

.ant-collapse-content > .ant-collapse-content-box {
  p {
    .font({.display-xs()});
    color: @gray-500;
  }
}
@media only screen and (max-width: @md) {
  .br-collapse-container {
    padding: 32px 16px;
    .br-collapse-panel {
      width: 100%;
    }
  }
}
@media only screen and (max-width: @xs) {
  .br-collapse-container {
    h1 {
      .font({.signup-md()});
    }
    .ant-collapse
      > .ant-collapse-item
      > .ant-collapse-header
      .ant-collapse-header-text {
      font-size: 14px;
      line-height: 18px;
    }

    .ant-collapse-content > .ant-collapse-content-box {
      padding: 0 16px;
      p {
        font-size: 12px;
        line-height: 14px;
        color: @gray-500;
      }
    }
  }
}
