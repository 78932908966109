@import 'styles/variables.less';

.br-feature-container {
  background-color: #f3fafb;
  padding: 32px 24px;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
  align-self: stretch;
  h1 {
    .font({.signup-md()});
    color: @gray-900;
    margin-bottom: 16px;
  }
  p {
    .font({.display-xs()});
    color: @gray-500;
    // min-height: calc(24px * 4);
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .br-image-container {
    min-height: 63px;
  }
  svg {
    width: 20px;
    height: 20px;
    flex-shrink: 0;
  }
  Svg > * {
    fill: @teal-500;
  }
  .br-feature-list {
    display: flex;
    flex-direction: column;
    gap: 8px;
    .br-feature-list-item {
      display: flex;
      gap: 8px;
      align-items: center;
      span {
        display: inline;
        color: @gray-900;
        .font({.heading()});
      }
    }
  }
}
@media only screen and (max-width: @sm) {
  .br-feature-container {
    p {
      min-height: auto;
    }
  }
}
@media only screen and (max-width: @xs) {
  .br-feature-container {
    p {
      font-size: 14px;
      line-height: 18px;
      margin-top: 12px;
      margin-bottom: 12px;
    }
    .br-feature-list {
      .br-feature-list-item {
        span {
          font-size: 14px;
          line-height: 18px;
        }
      }
    }
  }
}
