@import 'styles/variables.less';

html[dir='rtl'] .br-hero-container-right {
  img {
    object-position: right;
  }
}
html[dir='ltr'] .br-hero-container-right {
  img {
    object-position: left;
  }
}
html[dir='rtl'] .br-hero-container-left .br-hero-container-left-button {
  width: 116px;
}

.br-hero-container {
  background-color: @teal-25;
  display: flex;
  flex-direction: row;
  align-self: stretch;
  justify-content: space-between;
  padding: 68px 0px 67.373px 64px;
}
.br-hero-container-left {
  margin-top: 60px;
  width: 58%;
  display: flex;
  flex-direction: column;
  h1 {
    .font({.display-xl()});
    span {
      color: @teal-500;
      display: inline;
    }
  }
  p {
    margin-top: 10px;
    .font({.signup-md()});
    color: @gray-500;
    span {
      display: inline;
      color: @gray-900;
    }
  }
  .br-hero-container-left-button {
    background-color: @bosta-red;
    color: white;
    border: none;
    border-radius: 8px;
    padding: 12px 24px;
    width: 148px;
    height: 48px;
    .font({.button-lg()});
    box-shadow: @shadow-xs;
    cursor: pointer;
    margin-top: 16px;
  }
  .br-hero-container-left-stats {
    display: flex;
    gap: 32px;
    .br-hero-container-left-stats-item {
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      .br-hero-container-left-stats-item-number {
        margin-top: 40px;
        .font({.display-lg()});
        color: @bosta-red;
      }
      .br-hero-container-left-stats-item-text {
        .font({.display-sm()});
        color: @gray-500;
        font-weight: 600;
      }
    }
  }
}
.br-hero-container-right {
  width: 42%;
  img {
    width: 100%;
    height: 588.627px;
    object-fit: cover;
  }
}
@media only screen and (max-width: @md) {
  .br-hero-container {
    padding: 32px 16px;
    flex-direction: column-reverse;
    padding: 0 10px;
    text-align: center;
    align-items: center;
    .br-hero-container-left {
      width: 100%;
      .br-hero-container-left-button {
        margin: auto;
      }
      .br-hero-container-left-stats {
        margin: auto;
      }
    }
    .br-hero-container-right {
      width: 100%;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}
@media only screen and (max-width: @sm) {
  .br-hero-container {
    .br-hero-container-left {
      .br-hero-container-left-stats {
        gap: 20px;
        .br-hero-container-left-stats-item {
          .br-hero-container-left-stats-item-number {
            font-size: 20px;
            line-height: 24px;
          }
          .br-hero-container-left-stats-item-text {
            font-size: 16px;
            line-height: 24px;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: @xs) {
  html[dir='rtl'] .br-hero-container-left-stats-item-text {
    padding-inline: 7px;
  }
  .br-hero-container {
    padding: 32px 16px;
    .br-hero-container-left {
      margin-top: 32px;
      h1 {
        margin-bottom: 12px;
        .font({.signup-md()});
        font-size: 20px;
        line-height: 28px;
      }
      p {
        margin-top: 0px;
        margin-bottom: 24px;
        font-size: 14px;
        line-height: 18px;
      }
      .br-hero-container-left-button {
        width: auto;
        height: auto;
        padding: 8px 16px;
        font-size: 14px;
      }

      .br-hero-container-left-stats-item {
        margin-top: 0px;
      }
    }
  }
}
