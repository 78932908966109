@import 'styles/variables.less';
html[dir='rtl'] {
  .br-landing-signup-logo {
    bottom: 170px;
    width: 310px;
  }
  .br-landing-signup-image {
    bottom: 70px;
  }
}
.br-landing-signup {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 48px;
  padding: 96px 64px;
  background-color: white;

  .br-landing-signup-image {
    position: absolute;
    bottom: 210px;
    right: 0;
    z-index: 0;
  }

  .br-landing-signup-logo {
    position: absolute;
    left: 0;
    bottom: 20px;
    width: 220px;
  }

  h1 {
    .font({.display-xl()});
    text-align: center;
    max-width: 964px;
    span {
      color: @bosta-red;
      display: inline;
    }
  }

  .br-landing-signup-form {
    position: relative;
    background-color: white;
    opacity: 1;
    z-index: 10;
    padding: 32px 50px;
    gap: 24px;
    width: 528px;
    border-radius: 12px;
    box-shadow: (@shadow-md);
    display: flex;
    flex-direction: column;
    align-items: center;
    .br-landing-signup-form__phone {
      margin-bottom: 20px;
    }
    form {
      width: 100%;
    }

    h1 {
      .font({.display-md()});
    }

    .br-landing-submit-btn {
      width: 100%;
      background-color: @bosta-red;
      border-radius: 8px;
      min-height: 48px;
      .font({.button-lg()});
    }
  }
}

@media only screen and (max-width: @md) {
  .br-landing-signup {
    .br-landing-signup-form {
      width: 100%;
    }
  }
}
@media only screen and (max-width: @sm) {
  html[dir='rtl'] h1 {
    font-size: 20px;
  }
  html[dir='rtl'] .br-landing-signup-image {
    width: 160px;
    bottom: -12px;
    inset-inline-start: 15px;
  }
  html[dir = 'ltr'] .br-landing-signup-image {
    width: 130px;
    bottom: -8px;
    right: -70px;
  }
  .br-landing-signup {
    padding: 32px 16px;
    .br-landing-signup-form {
      padding: 24px;
    }
    h1 {
      font-size: 24px;
      line-height: 28px;
    }
  }
}
