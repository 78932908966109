@import 'styles/variables.less';
html[dir='ltr'] .br-features-cloud {
  right: 50px;
}
html[dir='rtl'] .br-features-logo {
  transform: scaleX(-1);
}
html[dir='rtl'] .br-features-cloud {
  left: 50px;
}
html[dir='rtl'] .br-features-cloud-bottom {
  left: -37px;
  transform: rotate(5.462deg);
}
html[dir = 'rtl'] .br-features-sign-up .br-feature-sign-up-button{
  width: 116px;
}
.br-features-container {
  position: relative;
  padding: 96px 64px;

  .br-features-cloud {
    position: absolute;
    top: 30px;
    width: 154px;
  }
  .br-features-cloud-bottom {
    position: absolute;
    bottom: 139px;
    right: -37px;
    transform: rotate(-5.462deg);
    opacity: 0.3;
  }
  .br-features-logo {
    position: absolute;
    bottom: -70px;
    left: 0;
  }
}
.br-features-text {
  display: flex;
  justify-content: center;
  text-align: center;
  h1 {
    color: @teal-500;
    .font({.display-xl()});
    margin-bottom: 0;
  }
}
.br-features-list {
  margin-block: 32px;
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 20px;
}
.br-features-sign-up {
  position: relative;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  justify-content: center;
  align-items: center;
  text-align: center;
  z-index: 10;
  p {
    color: @gray-500;
    line-height: 24px;
    font-size: 16px;
    font-family: @font-semibold-2;
  }
  .br-feature-sign-up-button {
    background-color: @bosta-red;
    color: white;
    border: none;
    border-radius: 8px;
    padding: 12px 24px;
    width: 148px;
    height: 48px;
    .font({.button-lg()});
    cursor: pointer;
    box-shadow: @shadow-xs;
  }
}
@media only screen and (max-width: @md) {
  .br-features-list {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
@media only screen and (max-width: @sm) {
  .br-features-container {
    padding: 32px 16px;
    .br-features-cloud {
      width: 60px;
      top: 10px;
    }
  }
  .br-features-list {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}
@media only screen and (max-width: @xs) {
  .br-features-container {
    padding: 32px 16px;
    .br-features-text {
      h1 {
        .font({.signup-md()});
      }
    }
  }
  html[dir='ltr'] .br-features-cloud {
    right: 0;
    top: 0;
  }
  html[dir='rtl'] .br-features-cloud {
    left: 0;
  }
  .br-features-cloud-bottom {
    display: none;
  }
  .br-features-logo {
    width: 50px;
    top: 5px;
  }
  html[dir='ltr'] .br-features-logo {
    left: -20px;
  }
  html[dir='rtl'] .br-features-logo {
    inset-inline-end: -20px;
    left: unset;
    transform: none;
  }
  .br-features-sign-up {
    p {
      font-size: 15px;
      line-height: 20px;
    }
    .br-feature-sign-up-button {
      width: auto;
      height: auto;
      padding: 8px 14px;
      font-size: 14px;
      line-height: 20px;
    }
  }
}
