@import 'styles/variables.less';
html[dir='rtl'] .br-services-cloud-bottom {
  left: -64px;
  transform: rotate(5.462deg);
}
.br-services-container {
  padding: 96px 64px;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
  position: relative;
  .br-services-cloud {
    position: absolute;
    top: 30px;
    right: 50px;
  }
  .br-services-cloud-bottom {
    position: absolute;
    bottom: 224px;
    right: -64px;
    transform: rotate(-5.462deg);
    opacity: 0.8;
  }
  h1 {
    .font({.display-xl()});
    color: @teal-500;
    margin-bottom: 12px;
  }
  p {
    max-width: 1000px;
    .font({.signup-md()});
    color: @gray-500;
    margin-bottom: 48px;
  }
  .br-services-components {
    z-index: 10;
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    row-gap: 16px;
    p {
      margin-bottom: 0px;
    }
  }
}
@media only screen and (max-width: @md) {
  .br-services-container {
    padding: 96px 20px;
    .br-services-components {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
  }
}
@media only screen and (max-width: @sm) {
  .br-services-container {
    .br-services-components {
      grid-template-columns: repeat(1, minmax(0, 1fr));
      gap: 0px;
    }
  }
}
@media only screen and (max-width: @xs) {
  .br-services-container {
    padding: 32px 16px;
    h1 {
      font-size: 24px;
      line-height: 28px;
    }
    p {
      font-size: 14px;
      line-height: 18px;
      margin-bottom: 32px;
    }

    .br-services-cloud {
      width: 57px;
      top: 0px;
    }
    .br-services-cloud-bottom {
      width: 80px;
      top: 40%;
    }
  }
  html[dir='rtl'] .br-services-cloud-bottom {
    right: 0;
    left: auto;
  }
  html[dir='ltr'] .br-services-cloud-bottom {
    left: 0;
  }
  html[dir='rtl'] .br-services-cloud {
    left: 0;
  }
  html[dir='ltr'] .br-services-cloud {
    right: 0;
  }
}
