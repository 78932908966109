@import 'styles/variables.less';

.br-service-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  padding: 24px 24px;
  .br-service-icon-div {
    width: 48px;
    height: 48px;
    border-radius: 100%;
    background-color: @teal-200;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 8px;
    svg {
      width: 28px;
      height: 28px;
    }
    svg > * {
      fill: @teal-500;
    }
  }
  .br-service-title{
    color: @gray-900;
    .font({.signup-md()});
    margin-bottom: 0;
  }
  .br-service-desc{
    color: @gray-500;
    .font({.display-xs()});
  }
}
@media only screen and (max-width: @xs) {
  .br-service-container {
    .br-service-desc{
      font-size: 14px;
      line-height: 18px;
    }
  }
}
