@import 'styles/fonts-variables.less';
@import 'styles/variables.less';

.br-container__footer.ant-layout-footer {
  min-height: 280px;
  background-color: #e8f8fa;
  display: flex;
  padding: 62px 10%;
}

html[dir='rtl'] .br-container__footer__contact {
  direction: rtl;
}

.br-container__footer__contact-whatsapp a {
  display: flex;
  align-items: center;
  gap: 4px;
}

.br-container__footer-links-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  
}
.br-container__footer ul:first-child {
  margin-left: 99px;
}

.br-container__footer ul {
  list-style-type: none;
  padding: 0;
  & li:first-child {
    font-family: @font-bold-1;
    font-style: normal;
    font-size: 16px;
    line-height: 19px;
  }
  & li {
    margin-bottom: 19px;
    a {
      color: #4f5665;
    }
    a.br-container__footer-links-container-integrations{
      color: @gray-900;
    }
  }
  & li:not(:first-child) {
    cursor: pointer;
  }
  & li:first-child {
    cursor: default;
  }
}

.br-container__footer ul li.br-container__footer__track-shipment {
  text-decoration: underline;
  cursor: pointer;
}

@media only screen and (max-width: 991px) {
  .br-container__footer.ant-layout-footer {
    flex-direction: column;
    align-items: flex-start;
    .anticon {
      margin-bottom: 50px;
    }
  }

  html[dir='rtl'] .br-container__footer__contact {
    direction: rtl;
  }
  
  .br-container__footer-links-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    ul {
      width: 50%;
      margin-bottom: 50px;
    }
    ul:first-child {
      margin-left: 0;
    }
  }
}
