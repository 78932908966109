@import 'styles/variables.less';

html[dir='rtl'] .br-steps-logo {
  inset-inline-end: 0;
}
html[dir='ltr'] .br-steps-logo {
  inset-inline-start: 0;
}
html[dir='rtl'] .br-steps-car {
  inset-inline-start: 0;
}
html[dir='ltr'] .br-steps-car {
  inset-inline-end: 0;
}

.br-steps-container {
  padding: 96px 64px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
  .br-steps-logo {
    position: absolute;
    top: 5px;
  }
  .br-steps-car {
    position: absolute;
    bottom: 30px;
  }
  h1 {
    .font({.display-xl()});
    color: @teal-500;
    z-index: 10;
  }
  p {
    .font({.display-md()});
    color: @gray-500;
    z-index: 10;
    max-width: 974px;
  }
  .br-steps-line-combined {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 75%;
    margin: 30px auto;
    .br-steps-line-connector {
      position: absolute;
      top: 23%;
      width: 78%;
      border-bottom: 1.5px solid @teal-500;
      z-index: 0;
      margin-inline-start: 11.1%;
      background-color: @teal-500;
    }
    .br-steps-line {
      display: flex;
      flex-direction: column;
      align-items: center;
      background: white;
      padding: 0 15px;
      gap: 16px;
      .br-steps-line-step {
        position: relative;
        z-index: 1;
        width: 49px;
        height: 49px;
        border-radius: 100%;
        background-color: @teal-200;
        display: flex;
        justify-content: center;
        align-items: center;
        span {
          color: @teal-500;
          .font({.signup-md()});
        }
      }
      .br-steps-line-title {
        span {
          color: @gray-900;
          .font({.signup-md()});
        }
      }
    }
  }
  .br-steps-line_vertical {
    width: 90%;
    margin-top: 20px;
    display: none;
  }
  .ant-steps-vertical
    > .ant-steps-item
    > .ant-steps-item-container
    > .ant-steps-item-tail {
    border-right: 1.5px solid @teal-500;
  }
  .step-number_vertical {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: @teal-200;
    color: @teal-500;
    font-size: 16px;
  }
  span {
    color: @gray-900;
    .font({.signup-md()});
    width: max-content;
    text-align: center;
    text-align: center;
  }
}
@media only screen and (max-width: @md) {
  .br-steps-container {
    padding: 0px 20px;
  }
}
@media only screen and (max-width: @lg) {
  .br-steps-container {
    .br-steps-line-combined {
      width: 100%;
      .br-steps-line-connector {
        margin-inline-start: 15%;
        width: 71%;
      }
    }
  }
}
@media only screen and (max-width: @xs) {
  .br-steps-container {
    .br-steps-logo {
      width: 40px;
      top: -20px;
    }
    .br-steps-car {
      width: 120px;
    }
    h1 {
      font-size: 24px;
      line-height: 28px;
    }
    p {
      font-size: 14px;
      line-height: 18px;
    }
    .br-steps-line_vertical {
      display: flex;
      width: 100%;
      min-height: 300px;
      span {
        font-size: 20px;
      }
    }
    .br-steps-line-combined {
      display: none;
    }
  }
  html[dir='rtl'] .br-steps-car {
    inset-inline-start: auto;
    inset-inline-end: 0;
    transform: scaleX(-1);
  }
}
