@import 'styles/variables.less';

.br-banner-integrations {
  width: 100%;
  display: flex;
  margin-top: 16px;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  padding: 16px 12px;
  background-color: @teal-700;
  border-bottom: 3px solid @teal-400;
  color: white;
}

.br-banner-text {
  .br-banner-emoji{
    display: inline-block;
    margin-left: -3px;
  }
  flex-grow: 1;
  text-align: center;
  .font({.display-xs()});
  font-weight: 500;
  span{
    color: #66F1FF;
  }
}

.br-banner-close {
  background: none;
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
  padding: 8px;
}
@media only screen and (max-width: @xs) {
  .br-banner-integrations{
    align-items: flex-start;
    justify-content: flex-start;
    text-align: start;
  }
  html[dir = 'rtl'] .br-banner-text{
    font-size: 15px;
    line-height: 22px;
  }
  .br-banner-text{
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
    text-align: start;
    
  }
}
